body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  height: 100vh;
  height: 100svh;
  background-color: black;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 177.777777778vh;
  max-width: 177.777777778svh;
  margin: 0 auto;
  position: relative;
}

.ratio-16-9 {
  position: relative;
  width: 100%;
  padding-top: 66.66%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ratio-16-9 .absolute {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.ratio-16-9 .relative {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}